<template>
    <v-app-bar app clipped-left color="primary">
        <v-toolbar-title>
            <v-icon color="white" class="rotated">local_shipping</v-icon>
            <router-link to="/" class="white--text">
                {{ APP_TITLE }}
            </router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu transition="slide-x-reverse-transition" tile flat open-delay="300" open-on-hover>
            <template v-slot:activator="{ on }">

                <v-btn icon v-on="on">
                    <v-icon class="white--text">person</v-icon>
                </v-btn>
            </template>
            <div class="camelCase title primary--text pa-3 info">
                Welcome,
                {{ username }}
                <v-row class="subtitle-2" align="center">
                    <v-col cols="auto" class="ml-auto">
                        <v-btn text @click="logout">Logout
                            <v-icon class="ml-2">mobile_off</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-menu>
    </v-app-bar>
</template>

<script>

export default {
    name: "NavBarTop",
    data: () => ({}),
    computed: {
        APP_TITLE() {
            return this.$store.getters.APP_TITLE
        },
        navigation() {
            return this.$store.getters.navigation
        },
        username() {
            return this.$store.getters.username
        },
    },
    methods: {
        navigationToggle(arg) {
            this.$store.dispatch('navigationToggle', {commit: 'navigation', data: arg})
        },
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push({name: 'login'})
                })
        }
    }
}
</script>

<style scoped lang="scss">
.rotated {
    transform: scaleX(-1);
}

.camelCase {
    text-transform: capitalize;
}
</style>